// src/Home.js
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { motion } from 'framer-motion'; // For scroll animations
import "./index.css";


const Home = () => {
  const threeRef = useRef(null); // Reference for Three.js spinning model
  const [currentSlide, setCurrentSlide] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0); // For survey progress
  const [showLoader, setShowLoader] = useState(false); // For survey loading effect
  const [carouselSlide, setCarouselSlide] = useState(0); // For additional carousel
  const [showResults, setShowResults] = useState(false); // For showing survey results
  const [answers, setAnswers] = useState([]); // To store survey answers
  const [finalAnswer, setFinalAnswer] = useState(''); // To store the final answer
  const [isSidebarOpen, setSidebarOpen] = useState(false); // For mobile sidebar navigation
  const [modalOpen, setModalOpen] = useState(false); // For the confirmation modal
  const [confirmCheck, setConfirmCheck] = useState(false); // For checkbox state in modal

  const slides = [
    "/images/snoopyland1.png",
    "/images/snoopyland2.png"
  ];

  const additionalSlides = [
    { src: "/images/snoopyland1.png", text: "Snoopy World - A delightful amusement park for all ages." },
    { src: "/images/snoopyland2.png", text: "Explore the magic of Snoopy World with fun rides and attractions!" }
  ];

  // Survey questions for the BIG SURPRISE section
  const surveyQuestions = [
    "Do you like the concept ideas of Snoopy World?",
    "Did you enjoy the look of the website?",
    "Would you visit Snoopy World?",
    "Could things be done better?",
    "If you, Ashley Madelane Teo, would do me the honor and officially be my girlfriend?"
  ];

  // Automatic carousel with dot navigation for the main concept art slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = "auto"; // Re-enable background scroll
    }
  }, [modalOpen]);
  
   

  // Automatic carousel for additional slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCarouselSlide((prevSlide) => (prevSlide + 1) % additionalSlides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [additionalSlides.length]);

  const selectSlide = (index) => {
    setCurrentSlide(index);
  };

  const selectCarouselSlide = (index) => {
    setCarouselSlide(index);
  };

  const handleAnswer = (answer) => {
    console.log(`Answer selected: ${answer}`); // Log the answer for debugging
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setAnswers((prevAnswers) => [...prevAnswers, answer]);
      if (questionIndex === surveyQuestions.length - 1) {
        setShowResults(true);
      } else {
        setQuestionIndex((prevIndex) => prevIndex + 1);
      }
    }, 1000);
  };

  const handleInputChange = (value) => {
    setFinalAnswer(value);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const submitFinalAnswer = () => {
    if (confirmCheck) {
      handleAnswer(finalAnswer);
      setModalOpen(false); // Close modal after submission
    }
  };

  return (
    <div className="bg-[#ECE4DB] text-gray-800 overflow-hidden">
      {/* Additional Floating Shapes */}
      <div className="shape shape-large shape-blue shape-float"></div>
      <div className="shape shape-medium shape-green shape-float-stagger-1"></div>
      <div className="shape shape-small shape-gray shape-float-stagger-2"></div>
      <div className="shape shape-extra-large shape-blue shape-float-stagger-3"></div>
      <div className="shape shape-medium shape-gray shape-float-stagger-4"></div>
      <div className="shape shape-small shape-green shape-float-stagger-5"></div>

      {/* Navigation Bar */}
      <nav className="w-full px-6 md:px-10 py-5 flex justify-between items-center bg-[#3D3935] text-white shadow-lg z-10 fixed top-0 left-0 right-0">
        <div className="text-2xl md:text-3xl font-bold text-[#F5EEDC]">SNOOPY WORLD</div>
        <div className="md:hidden hamburger-menu" onClick={toggleSidebar}>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </div>
        <ul className="hidden md:flex flex-col md:flex-row justify-center md:space-x-8 mt-4 md:mt-0">
          <li>
            <a href="#" className="relative text-[#F5EEDC] font-bold hover:text-white transition-all duration-300 hover:after:w-full after:absolute after:h-[2px] after:bg-[#F5EEDC] after:w-0 after:left-0 after:bottom-0 after:transition-all after:duration-300">
              Home
            </a>
          </li>
          <li>
            <a href="#concept-art" className="relative text-[#F5EEDC] font-bold hover:text-white transition-all duration-300 hover:after:w-full after:absolute after:h-[2px] after:bg-[#F5EEDC] after:w-0 after:left-0 after:bottom-0 after:transition-all after:duration-300">
              Concept Art
            </a>
          </li>
          <li>
            <a href="#big-surprise" className="relative text-[#F5EEDC] font-bold hover:text-white transition-all duration-300 hover:after:w-full after:absolute after:h-[2px] after:bg-[#F5EEDC] after:w-0 after:left-0 after:bottom-0 after:transition-all after:duration-300">
              BIG SURPRISE
            </a>
          </li>
        </ul>
        <button className="hidden md:flex items-center space-x-2 text-white hover:text-[#F5EEDC] transition-all duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M12 2a5 5 0 00-5 5v1.7a7 7 0 1010 0V7a5 5 0 00-5-5zm3 6.7V7a3 3 0 10-6 0v1.7a5 5 0 116 0z" clipRule="evenodd" />
          </svg>
          <span>About Us</span>
        </button>
      </nav>

      {/* Sidebar Navigation for Mobile */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <a href="#" onClick={toggleSidebar}>Home</a>
        <a href="#concept-art" onClick={toggleSidebar}>Concept Art</a>
        <a href="#big-surprise" onClick={toggleSidebar}>BIG SURPRISE</a>
      </div>

      {/* Hero Section */}
      <div className="relative min-h-screen flex flex-col items-center justify-center text-center bg-[#ECE4DB] px-6 md:px-10 py-32 gap-16 pt-20">
        {/* Geometric Shapes */}
        <div className="absolute top-0 left-0 w-[150px] md:w-[400px] h-[150px] md:h-[400px] bg-[#4F7942] rounded-full opacity-20 transform -translate-x-1/3 -translate-y-1/3 shape-float"></div>
        <div className="absolute bottom-0 right-0 w-[200px] md:w-[450px] h-[200px] md:h-[450px] bg-[#A0A3A3] rounded-full opacity-20 transform translate-x-1/3 translate-y-1/3 shape-float"></div>
        <div className="absolute top-1/4 right-1/4 w-[150px] md:w-[300px] h-[150px] md:h-[300px] bg-[#3D3935] rounded-full opacity-10 transform rotate-45 shape-float-stagger-3"></div>

        {/* Text Section */}
        <div className="max-w-screen-md space-y-8">
          <h1 className="text-4xl md:text-6xl font-bold text-[#4F7942] drop-shadow-lg">
            Welcome to <span className="text-[#3D3935]">SNOOPY WORLD</span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-700 lg:text-3xl max-w-lg mx-auto drop-shadow-sm">
            Explore a cozy world filled with treats, art, and surprises. Every visit is an adventure.
          </p>
          <button className="px-8 md:px-12 py-3 md:py-4 mt-8 bg-[#4F7942] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#3D3935] hover:scale-105">
            Explore Now
          </button>
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img src="./images/snoopy_flower.png" alt="Snoopy holding flowers" className="w-[150px] md:w-[300px] lg:w-[400px] h-auto object-contain shadow-md" />
        </div>
      </div>

      {/* Concept Art Section */}
      <div id="concept-art" className="relative min-h-screen flex flex-col items-center justify-center text-center bg-[#ECE4DB] px-4 md:px-6 py-32">
        <h2 className="text-5xl md:text-6xl font-bold text-[#3D3935] drop-shadow-lg mb-16">Concept Art</h2>

        {/* Additional Carousel Section */}
        <div className="relative w-full max-w-xl md:max-w-3xl mx-auto h-64 md:h-80 overflow-hidden rounded-lg shadow-lg mt-20">
          <div className="w-full h-full bg-cover bg-center transition-all duration-500 ease-in-out" style={{ backgroundImage: `url(${additionalSlides[carouselSlide].src})` }}></div>
          <div className="absolute bottom-8 left-0 w-full flex justify-center">
            <p className="text-white font-semibold text-base md:text-lg">{additionalSlides[carouselSlide].text}</p>
          </div>

          {/* Carousel Navigation Dots */}
          <div className="absolute bottom-4 left-0 w-full flex justify-center space-x-2 md:space-x-4">
            {additionalSlides.map((_, index) => (
              <span
                key={index}
                className={`w-3 h-3 md:w-4 md:h-4 rounded-full cursor-pointer ${index === carouselSlide ? 'bg-white' : 'bg-gray-400'}`}
                onClick={() => selectCarouselSlide(index)}
              ></span>
            ))}
          </div>
        </div>

        <div className="mt-20 space-y-20 max-w-screen-lg mx-auto vertical-spacing">
          {/* Snoopy Sushi Restaurant */}
          <motion.div
            initial={{ x: '-100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="flex flex-col md:flex-row items-center md:items-start justify-start md:space-x-16"
          >
            <img src="./images/Snoopysushi.png" alt="Snoopy Sushi Restaurant" className="w-[200px] md:w-[500px] h-[150px] md:h-[300px] rounded-lg shadow-lg" />
            <div className="mt-6 md:mt-0">
              <h3 className="concept-art-title"><strong>Snoopy Sushi Restaurant</strong></h3>
              <p className="concept-art-text">
                <strong>Dive into a delightful sushi experience at the Snoopy Sushi Restaurant! With a giant Snoopy welcoming you at the center, enjoy the freshest sushi in a fun, Snoopy-themed atmosphere.</strong>
              </p>
            </div>
          </motion.div>

          {/* Snoopy Strawberry Field */}
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-col md:flex-row items-center md:items-start justify-end md:space-x-16 mt-20"
          >
            <div className="mt-6 md:mt-0">
              <h3 className="concept-art-title"><strong>Snoopy Strawberry Field</strong></h3>
              <p className="concept-art-text">
                <strong>Take a stroll through the Snoopy Strawberry Field! Surrounded by adorable Snoopy statues and ripe strawberries, it's the sweetest spot in Snoopy World.</strong>
              </p>
            </div>
            <img src="./images/snoopystrawberry.png" alt="Snoopy Strawberry Field" className="w-[200px] md:w-[500px] h-[150px] md:h-[300px] rounded-lg shadow-lg" />
          </motion.div>

          {/* Snoopy Wingstop Restaurant */}
          <motion.div
            initial={{ x: '-100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="flex flex-col md:flex-row items-center md:items-start justify-start md:space-x-16 mt-20"
          >
            <img src="./images/snoopywingstop.png" alt="Snoopy Wingstop Restaurant" className="w-[200px] md:w-[500px] h-[150px] md:h-[300px] rounded-lg shadow-lg" />
            <div className="mt-6 md:mt-0">
              <h3 className="concept-art-title"><strong>Snoopy Wingstop Restaurant</strong></h3>
              <p className="concept-art-text">
                <strong>Enjoy your favorite wings with a Snoopy twist at the Snoopy Wingstop Restaurant. A fun and tasty experience awaits with Snoopy-inspired decor and delicious flavors.</strong>
              </p>
            </div>
          </motion.div>
        </div>
      </div>

      <div id="big-surprise" className="relative min-h-screen flex flex-col items-center justify-center text-center bg-[#ECE4DB] px-6 md:px-10 py-32">
        <h2 className="text-4xl md:text-5xl font-bold text-[#483C32] mb-12 tracking-wide">BIG SURPRISE!</h2>

        <div className="survey-form max-w-lg md:max-w-2xl w-full p-6 md:p-10 bg-[#FAF3E0] rounded-2xl shadow-xl border border-[#6D5847]">
          {showLoader ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center justify-center space-y-4"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 100, damping: 10 }}
                className="w-12 md:w-16 h-12 md:h-16 bg-[#4F7942] rounded-full flex items-center justify-center shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 md:h-8 w-6 md:w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.707-7.707a1 1 0 011.414 0L10 11.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </motion.div>
              <h3 className="text-xl md:text-2xl font-semibold text-[#4F7942]">Answer Saved!</h3>
            </motion.div>
          ) : (
            <>
              {questionIndex < surveyQuestions.length - 1 ? (
                <div className="flex flex-col items-center space-y-4">
                  <p className="text-xl md:text-2xl font-medium text-[#483C32] mb-6">
                    {questionIndex === surveyQuestions.length - 2
                      ? "This next question is the final and most important question."
                      : surveyQuestions[questionIndex]}
                  </p>
                  {questionIndex === surveyQuestions.length - 2 ? (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleAnswer("Are You Ready?")}
                      className="w-full px-4 md:px-6 py-3 md:py-4 bg-[#4F7942] text-[#FAF3E0] rounded-full shadow-md hover:shadow-lg transition-all duration-300 text-base md:text-lg font-semibold"
                    >
                      Are You Ready?
                    </motion.button>
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleAnswer("Yes")}
                        className="w-full px-4 md:px-6 py-3 md:py-4 bg-[#4F7942] text-[#FAF3E0] rounded-full shadow-md hover:shadow-lg transition-all duration-300 text-base md:text-lg font-semibold"
                      >
                        Yes
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleAnswer("No")}
                        className="w-full px-4 md:px-6 py-3 md:py-4 bg-[#4F7942] text-[#FAF3E0] rounded-full shadow-md hover:shadow-lg transition-all duration-300 text-base md:text-lg font-semibold"
                      >
                        No
                      </motion.button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <p className="text-xl md:text-2xl font-medium text-[#483C32] mb-6">
                    If you, Ashley Madelane Teo, would do me the honor and officially be my girlfriend:
                  </p>
                  <textarea
                    placeholder="Type your answer here..."
                    className="w-full px-4 py-3 border-2 border-[#4F7942] rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#4F7942] resize-none"
                    rows="3"
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setModalOpen(true)}
                    className="mt-4 w-full px-4 md:px-6 py-3 md:py-4 bg-[#4F7942] text-[#FAF3E0] rounded-full shadow-md hover:shadow-lg transition-all duration-300 text-base md:text-lg font-semibold"
                  >
                    Submit
                  </motion.button>
                </>
              )}

              <div className="mt-8">
                <div className="h-2 bg-[#ECE4DB] rounded-full overflow-hidden">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${((questionIndex + 1) / surveyQuestions.length) * 100}%` }}
                    className="h-2 bg-[#4F7942] rounded-full"
                    transition={{ duration: 0.5 }}
                  ></motion.div>
                </div>
                <p className="mt-2 text-[#6D5847] text-sm">
                  Question {questionIndex + 1} of {surveyQuestions.length}
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      {showResults && (
        <div className="results-page min-h-screen flex flex-col items-center justify-center text-center bg-[#ECE4DB] px-4 md:px-6 py-32">
          <h2 className="text-4xl md:text-5xl font-bold text-[#483C32] mb-12">Your Responses</h2>
          <div className="bg-[#FAF3E0] rounded-xl p-6 md:p-8 shadow-lg border border-[#6D5847] w-full max-w-lg md:max-w-3xl">
            <ul className="space-y-4 text-left">
              {answers.map((answer, index) => (
                <li key={index} className="text-[#483C32] text-base md:text-lg">
                  <strong>Question {index + 1}:</strong> {surveyQuestions[index]} <br />
                  <span className="text-[#4F7942]">{answer}</span>
                </li>
              ))}
            </ul>
            <div className="mt-8 text-center">
              <h3 className="text-2xl md:text-3xl font-bold text-[#4F7942]">
                {finalAnswer.toLowerCase() === "yes" ? 
                  "If you said yes! Then thank you!! That's so awesome sauce MWAHH 💖" : 
                  "If you said no, then aww man </3"}
              </h3>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Confirmation */}
{modalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
  <div className="bg-white rounded-lg shadow-xl p-6 max-w-lg w-full sm:max-w-md md:max-w-lg lg:max-w-xl modal-content">
    <h3 className="text-xl sm:text-2xl font-bold text-center text-[#4F7942] mb-4 sm:mb-6">Confirm Your Answer</h3>
    <p className="text-gray-800 text-base leading-relaxed space-y-4"></p>
    
    <div className="text-gray-800 text-sm sm:text-base leading-loose space-y-6">
      <div>
        <strong className="text-gray-800 text-sm sm:text-base leading-relaxed space-y-2 sm:space-y-4">Some Things to Consider:</strong>
        <ul className="list-disc list-inside pl-4">
          <li>I can be terminally sleepy at times. 😴</li>
          <li>
            I may be a little silly. I'm riddled with <em>BRAIN SOUP AND SILLY THOUGHTS</em> (lol). But you make me happy, so Fair warning: I might stay up for like three days straight from brain so don't get spooked. 😅
          </li>
          <li>I CAN OFFER YOU ALL THE LOVE IN THE WORLD! (🤍🤍🤍)</li>
          <li>I’m ready to give you my best and do whatever makes you happy. 😊</li>
          <li>
            I suffer from <em>Terminally Locked-In Syndrome</em>: utterly devoted to you for as long as you’ll have me. 💕
          </li>
        </ul>
      </div>

      <div>
        <strong className="block mb-2">Perks of Being My Girlfriend:</strong>
        <ul className="list-disc list-inside pl-4 space-y-2">
          <li>Unlimited time together – your wish is my command!</li>
          <li>As many kisses as you want. 😘</li>
          <li>I’ll always be there for you – whether it’s a hug, a kiss, or just a listening ear. 🫂</li>
          <li>Request visits anytime! I’ll fly to you or drive over or I will pay for your ticket to me! 🚗✈️</li>
          <li>Anything you ask for, I’ll happily do!</li>
        </ul>
      </div>

      <div>
        <strong className="block mb-2">Final Note:</strong>
        <p>
          By confirming your interest, you accept the <em>lifelong position</em> of "Being Mine" and me Being Yours. This commitment is legally binding (lol). You’ll be stuck with me forever and ever. 😈
        </p>
        <p>So, please confirm below if you’d like to officially be with Owen Youssef Azemoun. 🥰</p>
      </div>
    </div>

    <div className="mt-4 flex items-start">
      <input
        type="checkbox"
        checked={confirmCheck}
        onChange={(e) => setConfirmCheck(e.target.checked)}
        className="w-4 h-4 text-[#4F7942] focus:ring-[#4F7942] border-gray-300 rounded"
      />
      <label htmlFor="confirm" className="ml-2 text-sm sm:text-base font-medium text-gray-900">
        Why Yes I Would Love To Be With You.
      </label>
    </div>

    <div className="flex justify-end space-x-4 mt-6">
      <button
        onClick={() => setModalOpen(false)}
        className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 text-sm sm:text-base font-semibold"
      >
        Cancel
      </button>
      <button
        onClick={submitFinalAnswer}
        className="px-4 py-2 bg-[#4F7942] text-white rounded-md hover:bg-[#3D3935] text-sm sm:text-base font-semibold"
      >
        Submit Answer
      </button>
    </div>
  </div>
</div>
)}

      {/* Footer */}
      <footer className="bg-[#3D3935] text-white py-8 text-center">
        <div>© 2024 Snoopy World. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default Home;
