// src/App.js
import React from 'react';
import Home from './Home'; // Importing the one-page design component
import './index.css';

function App() {
  return <Home />;
}

export default App;
